<template>
  <div class="znl-stock-query" v-loading="p_loading" element-loading-text="数据加载中,请稍后...">
    <!-- 搜索条件 -->
    <el-row class="el-row-items">
      <znl-input
        form-type="input"
        :disabled="false"
        placeholder="（型号）"
        width="100px"
        size="mini"
        layout="left"
        v-model="modelTxt"
        :border="true"
        @keyup.enter.native="pageSearch()"
        type="text"
      ></znl-input>
      <znl-input
        form-type="input"
        :disabled="false"
        placeholder="（品牌）"
        width="100px"
        size="mini"
        layout="left"
        v-model="brandTxt"
        :border="true"
        @keyup.enter.native="pageSearch()"
        type="text"
      ></znl-input>
      <znl-input
        form-type="input"
        :disabled="false"
        placeholder="（封装）"
        width="100px"
        size="mini"
        layout="left"
        v-model="packagingTxt"
        :border="true"
        @keyup.enter.native="pageSearch()"
        type="text"
      ></znl-input>
      <znl-input
        form-type="input"
        :disabled="false"
        placeholder="（客户名称）"
        width="130px"
        size="mini"
        layout="left"
        v-model="customerName"
        :border="true"
        @keyup.enter.native="pageSearch()"
        type="text"
      ></znl-input>

      <znl-input
        layout="left"
        form-type="select"
        :disabled="false"
        width="100px"
        placeholder="-请选择-"
        popper-class="popperClass"
        :clearable="true"
        :border="true"
        inp-perc="100px"
        :select-options="StkInSearchFields"
        v-model="fieldTxt"
      ></znl-input>

      <znl-input
        form-type="input"
        :disabled="false"
        placeholder="请选择搜索条件"
        width="100px"
        size="mini"
        layout="left"
        v-model="fieldSearchTxt"
        :border="true"
        @keyup.enter.native="pageSearch()"
        type="text"
      ></znl-input>

      <znl-button @click="pageSearch()" style-type="mac" :height="22" class="search-btn">
        <span style="color:#fff">
          <i class="iconfont icon-search_ic"></i> 搜索
        </span>
      </znl-button>
      <el-checkbox v-model="isZero" @change="isZeroChange()">包含零库存</el-checkbox>
    </el-row>
    <!-- 搜索条件 end -->

    <!-- 页签 -->
    <el-tabs
      v-model="activeName"
      value="StockQuery"
      @tab-click="pageSearch()"
      class="znl-bottom-tab nav-common-ui tab-btnstyle-box"
    >
      <el-tab-pane
        label="我的库存"
        v-if='hasRight("StkStock")'
        :style="height"
        name="StockQuery"
      >
        <znl-stock
          ref="stockMini"
          v-if="tabInitStatus.StockQuery"
          :init-data="false"
          :isVisibleAdopt="isVisibleAdopt"
          :height="height"
          :searchModel="stockSearchModel"
          :after-grid-init="afterGridInit"
          @dbl-click="onRowDblClick"
        ></znl-stock>
      </el-tab-pane>
      <el-tab-pane
        label="供应商库存"
        v-if='hasRight("StockVMI")'
        :style="height"
        name="StockVMIMini"
      >
        <znl-stock-vmi
          ref="stockvmiMini"
          v-if="tabInitStatus.StockVMIMini"
          :init-data="false"
          :height="height"
          :isVisibleAdopt="isVisibleAdopt"
          :searchModel="stockVMISearchModel"
          :after-grid-init="afterGridInit"
          @dbl-click="row => {onRowDblClick(row, 'StockVMIMini')}"
        ></znl-stock-vmi>
      </el-tab-pane>
      <el-tab-pane
        label="询价记录"
        v-if='hasRight("StkInquireRecord")'
        name="Inquire"
        :style="height"
      >
        <inquire-details
          ref="stockInquire"
          v-if="tabInitStatus.Inquire"
          :init-data="false"
          :height="height"
          :after-grid-init="afterGridInit"
          @dbl-click="row => {onRowDblClick(row, 'Inquire')}"
        ></inquire-details>
      </el-tab-pane>

      <el-tab-pane
        label="报价记录 - 按客户"
        v-if='hasRight("BomMastLineSelect")'
        name="OfferPriceByCustomer"
        :style="height"
      >
        <bom-quote-details
          ref="offerPriceByCustomer"
          v-if="tabInitStatus.OfferPriceByCustomer"
          :is-show-header="false"
          :is-show-operate-col="false"
          :init-data="false"
          :height="height"
          :isVisibleAdopt="isVisibleAdopt"
          :after-grid-init="afterGridInit"
          @dbl-click="row => {onRowDblClick(row, 'OfferPriceByCustomer')}"
        ></bom-quote-details>
      </el-tab-pane>

      <el-tab-pane
        label="销售记录 - 按客户"
        v-if='hasRight("PastStkOut")'
        name="StkOutRecordByCustomer"
        :style="height"
      >
        <stk-out-details
          ref="stkOutRecordByCustomer"
          v-if="tabInitStatus.StkOutRecordByCustomer"
          :is-operate="false"
          :init-data="false"
          :height="height"
          :isVisibleAdopt="isVisibleAdopt"
          :after-grid-init="afterGridInit"
          @dbl-click="row => {onRowDblClick(row, 'StkOutRecordByCustomer')}"
        ></stk-out-details>
      </el-tab-pane>

      <el-tab-pane
        label="报价记录 - 按型号"
        v-if='hasRight("BomMastLineSelect")'
        name="OfferPriceByModel"
        :style="height"
      >
        <bom-quote-details
          ref="OfferPriceByModel"
          v-if="tabInitStatus.OfferPriceByModel"
          :is-show-header="false"
          :is-show-operate-col="false"
          :init-data="false"
          :height="height"
          :isVisibleAdopt="isVisibleAdopt"
          :after-grid-init="afterGridInit"
          @dbl-click="row => {onRowDblClick(row, 'OfferPriceByCustomer')}"
        ></bom-quote-details>
      </el-tab-pane>

      <el-tab-pane
        label="销售记录 - 按型号"
        v-if='hasRight("PastStkOut")'
        name="StkOutRecordByModel"
        :style="height"
      >
        <stk-out-details
          ref="StkOutRecordByModel"
          v-if="tabInitStatus.StkOutRecordByModel"
          :is-operate="false"
          :init-data="false"
          :height="height"
          :isVisibleAdopt="isVisibleAdopt"
          :after-grid-init="afterGridInit"
          @dbl-click="row => {onRowDblClick(row, 'StkOutRecordByModel')}"
        ></stk-out-details>
      </el-tab-pane>

      <el-tab-pane
        label="采购记录"
        v-if='hasRight("PastStkIn")'
        name="StkInRecord"
        :style="height"
      >
        <stk-in-details
          ref="stkInRecord"
          v-if="tabInitStatus.StkInRecord"
          :is-operate="false"
          :init-data="false"
          :height="height"
          :isVisibleAdopt="isVisibleAdopt"
          :after-grid-init="afterGridInit"
          @dbl-click="row => {onRowDblClick(row, 'StkInRecord')}"
        ></stk-in-details>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import ZnlStock from "@c_modules/Stk/StkStock_Mini_v1"
import ZnlStockVmi from "@c_modules/Stk/StockVMI_Mini"
import InquireDetails from '@c_modules/RFQ/InquireDetails'

// 会造成循环引用的组件通过其他方式导入
// import BomQuoteDetails from '@c_modules/BOM/BomQuoteDetails'
// import StkOutDetails from '@c_modules/Stk/StkOutDetails'
// import StkInDetails from '@c_modules/Stk/StkInDetails'
// 会造成循环引用的组件通过其他方式导入 end

export default {
  name: "StockQueryTabsBar",
  components: {
    ZnlStock,
    ZnlStockVmi,
    InquireDetails,

    // BomQuoteDetails,
    // StkOutDetails,
    // StkInDetails
  },
  data() {
    return {
      config: {
        DictItemURL: "DictItem/GetItemValue",
        getUrl: 'PageParam/GetOne',
        saveUrl: 'PageParam/SaveOne',
        GCode: 'StkStock',
        ParamCode: 'IsZeroInventory',
        includeZeroStock: false
      },
      p_loading: false,
      AdoptstkGUID: "", // 采纳过的stkGUID
      modelTxt: "",
      brandTxt: "",
      packagingTxt: "",
      fieldTxt: "",
      fieldSearchTxt: "",
      isZero: false,
      customerName: "",
      stockSearchModel: {Model: ''},
      stockVMISearchModel: {},
      stkOutSearchByModel: {},
      stkOutSearchByCustomer: {},
      stkInSearchModel: {},
      InquireSearchModel: {}, // 询价查询
      Hisofferprice: {}, // 报价记录（按客户）
      HisofferpriceByModel: {}, // 报价记录（按型号）
      officePriceByCustomer: {}, // 报价记录（按客户）
      salesSearchModel: {},
      StkInSearchFields: [],
      activeName: "StockQuery",
      listHeight: '245px',
      tabInitStatus: {
        StockQuery: false,
        StockVMIMini: false,
        Inquire: false,
        StkInRecord: false,
        OfferPriceByCustomer: false,
        StkOutRecordByCustomer: false,
        OfferPriceByModel: false,
        StkOutRecordByModel: false
      },
      isFirstLoad: true
    }
  },
  props: {
    searchModel: {
      type: Object,
      default: () => {
        return {
          Model: "",
          CustomerName: "",
          StkGUID: ""
        }
      }
    },
    isVisibleAdopt: {
      // 是否显示采购列
      type: Boolean,
      default: false
    },
    height: {
      type: String,
      default: '245px'
    }
  },
  watch: {
    searchModel: {
      handler: function(value, oldValue) {
        this.onPageSearch()
      }
      // deep: true
    }
  },
  methods: {
    async getPageData() {
      let pageUrl = this.$store.state.accountInfo.pageAuthorUrl
      return await this.$post(pageUrl, { PageCode: "VStock" })
    },
    hasRight(code) {
      let show = _.some(this.pageData, page => page.Code === code)
      return show
    },
    async onInit() {
      this.p_loading = true
      let pageUrl = this.$store.state.accountInfo.pageAuthorUrl
      await this.getConfig()
      return this.$post(pageUrl, { PageCode: "VStock" }, data => {
        this.pageData = data
        this.p_loading = true
        this.onInitFieldList()

        // 判断权限
        if (this.hasRight("StkStock")) { // 库存
          this.tabInitStatus.StockQuery = true
        }
        else if (this.hasRight('StockVMI')) { // 供应商库存
          this.tabInitStatus.StockVMIMini = true
        }
        else if (this.hasRight('StkInquireRecord')) { // 询价记录
          this.tabInitStatus.Inquire = true
        }
        else if (this.hasRight('BomMastLineSelect')) { // 报价记录
          this.tabInitStatus.OfferPriceByCustomer = true
          this.tabInitStatus.OfferPriceByModel = true
        }
        else if (this.hasRight('PastStkOut')) { // 发货记录
          this.tabInitStatus.StkOutRecordByCustomer = true
          this.tabInitStatus.StkOutRecordByModel = true
        }
        else if (this.hasRight('PastStkIn')) { // 入库记录
          this.tabInitStatus.StkInRecord = true
        }
      })
    },

    isZeroChange() {
      this.pageSearch()
      this.saveConfig()
    },

    async getConfig () {
      await this.$post(this.config.getUrl, { GCode: this.config.GCode, ParamCode: this.config.ParamCode }, data => {
        this.isZero = (data.ParamValue === 'true')
      })
    },

    saveConfig () {
      this.$post(this.config.saveUrl, { GCode: this.config.GCode, ParamCode: this.config.ParamCode, ParamValue: this.isZero })
    },

    onInitFieldList() {
      return this.$post(
        this.config.DictItemURL,
        { DictCode: "StockSearchField" },
        data => {
          // 移除型号、品牌、封装
          let removeFields = ["Model", "Brand", "Packaging"]
          this.StkInSearchFields = _.filter(
            data,
            item => !_.includes(removeFields, item.ItemValue)
          )
          this.StkInSearchFields = _.map(this.StkInSearchFields, item => {
            return {
              key: item.ItemValue,
              value: item.ItemName
            }
          })
        }
      )
    },

    afterGridInit () {
      if (this.isFirstLoad) {
        this.isFirstLoad = false
      } else {
        this.pageSearch()
      }
    },

    onPageSearch(value, value2) {
      this.modelTxt = this.searchModel.Model
      this.customerName = this.searchModel.CustomerName
      this.AdoptstkGUID = _.isUndefined(this.searchModel.StkGUID) ? undefined : this.searchModel.StkGUID
      this.pageSearch()
    },
    pageSearch() {
      let searchModel = {
        Model: this.modelTxt,
        Brand: this.brandTxt,
        Packaging: this.packagingTxt,
        IsZero: this.isZero,
        CustomerName: this.customerName,
        AdoptstkGUID: this.AdoptstkGUID,
        Keywords: this.fieldSearchTxt,
        KeywordsField: this.fieldTxt
      }

      switch (this.activeName) {
        case 'StockQuery': // 我的库存
          this.tabInitStatus.StockQuery = true
          if (this.$refs.stockMini && !_.isEqual(this.stockSearchModel, searchModel)) {
            this.stockSearchModel = searchModel
            this.$refs.stockMini.onHeadSearch(searchModel)
          }
          break
        case 'StockVMIMini': // 供应商库存
          this.tabInitStatus.StockVMIMini = true
          if (this.$refs.stockvmiMini && !_.isEqual(this.stockVMISearchModel, searchModel)) {
            this.stockVMISearchModel = searchModel
            this.$refs.stockvmiMini.onHeadSearch(searchModel)
          }
          break
        case 'Inquire': // 询价记录
          this.tabInitStatus.Inquire = true
          if (this.$refs.stockInquire && !_.isEqual(this.InquireSearchModel, searchModel)) {
            this.InquireSearchModel = searchModel
            this.$refs.stockInquire.onHeadSearch(searchModel)
          }
          break
        case 'OfferPriceByCustomer': // 报价记录 - 按客户
          this.tabInitStatus.OfferPriceByCustomer = true
          delete searchModel.Model
          if (!this.hasValue(searchModel.CustomerName)) {
            this.$message({message: '请输入客户名称', type: 'error'})
            return false
          }
          if (this.$refs.offerPriceByCustomer && !_.isEqual(this.Hisofferprice, searchModel)) {
            this.officePriceByCustomer = searchModel
            this.$refs.offerPriceByCustomer.onHeadSearch(searchModel)
          }
          break
        case 'OfferPriceByModel': // 报价记录 - 按型号
          this.tabInitStatus.OfferPriceByModel = true
          delete searchModel.CustomerName
          if (!this.hasValue(searchModel.Model)) {
            this.$message({message: '请输入型号', type: 'error'})
            return false
          }
          if (this.$refs.OfferPriceByModel && !_.isEqual(this.HisofferpriceByModel, searchModel)) {
            this.HisofferpriceByModel = searchModel
            this.$refs.OfferPriceByModel.onHeadSearch(searchModel)
          }
          break
        case 'StkOutRecordByCustomer': // 出库记录 - 按客户
          this.tabInitStatus.StkOutRecordByCustomer = true
          delete searchModel.Model
          if (!this.hasValue(searchModel.CustomerName)) {
            this.$message({message: '请输入客户名称', type: 'error'})
            return false
          }
          if (this.$refs.stkOutRecordByCustomer && !_.isEqual(this.stkOutSearchByCustomer, searchModel)) {
            this.stkOutSearchByCustomer = searchModel
            this.$refs.stkOutRecordByCustomer.onHeadSearch(searchModel)
          }
          break
        case 'StkOutRecordByModel': // 出库记录 - 按型号
          this.tabInitStatus.StkOutRecordByModel = true
          delete searchModel.CustomerName
          if (!this.hasValue(searchModel.Model)) {
            this.$message({message: '请输入型号', type: 'error'})
            return false
          }
          if (this.$refs.StkOutRecordByModel && !_.isEqual(this.stkOutSearchByModel, searchModel)) {
            this.stkOutSearchByModel = searchModel
            this.$refs.StkOutRecordByModel.onHeadSearch(searchModel)
          }
          break
        case 'StkInRecord': // 出库记录
          this.tabInitStatus.StkInRecord = true
          if (this.$refs.stkInRecord && !_.isEqual(this.stkInSearchModel, searchModel)) {
            this.stkInSearchModel = searchModel
            this.$refs.stkInRecord.onHeadSearch(searchModel)
          }
          break
        default:
          break;
      }
    },

    onAdoptEnd(stkGUID, updateType) {
      // 采纳结束后前台显示值改变
      switch (updateType) {
        case "stock":
          this.$refs.stockMini.onAdoptEnd(stkGUID)
          break
        case "inquire":
          this.$refs.stockInquire.onAdoptEnd(stkGUID)
          break
        case "stkvmi":
          this.$refs.stockvmiMini.onAdoptEnd(stkGUID)
          break
      }
    },

    // 行双击时
    onRowDblClick (rowData, tabName = null) {
      let item = _.extend({}, rowData)
      item.Amount = null

      if (item === null) {
        return null
      }
      if (tabName === 'StockQuery') {
      }
      if (tabName === 'StockVMIMini') {
        item.BuyPrice = item.UnitPrice || null
      }
      if (tabName === 'Inquire') {
      }
      if (tabName === 'OfferPriceByCustomer') {
        item.BuyPrice = item.NTQuotePrice
        item.InvQty = item.Qty
      }
      if (tabName === 'StkOutRecordByCustomer') {
        item.InvQty = item.Qty
      }
      if (tabName === 'StkInRecord') {
        item.InvQty = item.Qty
      }

      item.__dataFrom = 'stock-query';

      this.$emit('row-dbl-click', item)
    }
  },
  beforeCreate () {
    this.$options.components.BomQuoteDetails = () => import('@c_modules/BOM/BomQuoteDetails')
    this.$options.components.StkOutDetails = () => import('@c_modules/Stk/StkOutDetails')
    this.$options.components.StkInDetails = () => import('@c_modules/Stk/StkInDetails')
  },
  created() {
    this.onInit().then(e => {
      this.p_loading = false
    })
  }
}
</script>
<style lang="scss">
.znl-stock-query {
  .nav-common-ui .el-tabs__nav {
    margin: 0 !important;
  }

  .tab-btnstyle-box {
    margin: 0 !important;
    >.el-tabs__header{
      background: #fff;
    }
    .el-tabs__content{
      margin: 0 !important;
    }
  }
  .znl-pagination{
    height: 22px !important;
    line-height: 22px !important;
  }
}
</style>
