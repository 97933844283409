<template>
  <!-- 我的库存 -->
  <div class="stock-query-box" :style="{ height: height }">
    <znl-table
      ref="table"
      gridtype="base"
      :columns="columns"
      :item-source="itemSource"
      :on-page-changed="onBindData"
      :page-size="pageSize"
      :page-index="pageIndex"
      :total-count="totalCount"
      v-loading="loading"
      element-loading-text="数据加载中,请稍后..."
      box-class="stock-query-box"
      :show-title-menus="true"
      :header-menus="getResetTableHeaderMenu('inquireLine')"
      @on-show-trigger="onHideShowColTrigger"
      :drag-done="onDragDone"
      @dbclick="onDblClick"
      @drag-col-position="
        (val) => {
          onSaveConfig(val, true);
        }
      "
      @on-config-save="
        (val) => {
          onSaveConfig(val, false);
        }
      "
      @on-reset-click="onResetTableConfigClick"
    >
      <div slot="header" class="v-table-toolbar" v-if="isAdopt">
        <znl-button
          style-type="main"
          class="btn-purchase-hide"
          :height="22"
          tip="请输入采纳量采纳"
          @click="OnAdoptPrice()"
        >
          <span>按采纳量采纳</span>
        </znl-button>
      </div>
    </znl-table>
  </div>
</template>

<script>
import { mixin as znlMethodsMixin } from "@c_common/znlGrid/scripts/znlMethods";
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
import { openLinkAll, erpTableSetHandler } from "@scripts/methods/common";
import { renderRequiredColumn } from "~assets/scripts/methods/common";

const CONFIG = {
  configURL: "StkStock/GetConfig",
  saveConfigURL: "StkStock/SaveConfig",
  resetConfigURL: "StkStock/ResetConfig",
  searchURL: "StkStockMini/Search",
  saveURL: "InquireAdopt/SaveByAdoptQty", // 按采纳量采纳
  saveSingleURL: "InquireAdopt/SaveByAdoptInfo", // 按单条询价采纳
};

export default {
  name: "StkStockMini",
  mixins: [
    znlMethodsMixin,
    getCommonDataMixin,
    openLinkAll,
    renderRequiredColumn,
    erpTableSetHandler,
  ],
  config: CONFIG,
  components: {},
  data() {
    return {
      columns: [],
      itemSource: [],
      Size: 10,
      pageIndex: 1,
      loading: false,
      totalCount: 0,
      role: "1",
      Status: true,
      // 需采纳量
      needAdoptQty: 0,
      IsSingle: false,
      isMainAccount: this.$store.state.accountInfo.IsMainAccount,
      permission: {
        viewBuyPrice: true, // 看采购价
        viewSalesPrice: true, // 看销售价
        noSeeSupplier: false, // 不看供应商
      },
    };
  },
  watch: {},
  computed: {
    pageSize: {
      get() {
        return this.Size;
      },
      set(val) {
        this.Size = val;
      },
    },
  },
  props: {
    isActivity: Boolean,
    initData: {
      type: Boolean,
      default: true,
    },
    pagePosition: {
      type: String,
      defaule: "bottom",
    },
    searchModel: {
      type: Object,
      default: () => {
        return {};
      },
    },
    searchQuery: {
      type: Object,
      defaule: () => {
        return {
          Model: "",
        };
      },
    },
    isFieldsSearch: {
      type: Boolean,
      default: true,
    },
    parentPageSize: {
      type: Number,
      default: 0,
    },
    height: {
      type: String,
      default: "290px",
    },
    // 是否启用分词搜索
    IsAnalyzer: {
      type: Boolean,
      default: false,
    },
    // 是否采纳库存量
    isAdopt: {
      type: Boolean,
      default: false,
    },
    afterGridInit: {
      // 表格初始化完成后调用
      type: Function,
    },
  },
  async created() {},
  async mounted() {
    this.onInit();
  },
  methods: {
    async onInit() {
      let config = await this.isSaveStorage("StkStock_Mini", CONFIG.configURL);
      //页面权限
      this.resourceList = config.ResourceList.map((item) => item.Code);

      // 获取模块打开或者关闭的状态
      let columns = config.ColumnConfigs;
      if (this.isAdopt) {
        columns = this.renderRequiredColumn(columns);
      }

      let tempConfig = [];
      this.isAdopt &&
        tempConfig.push({
          width: 36,
          type: "button",
          title: "采纳",
          btnTxt: "采纳",
          visible: true,
          isDeal: false,
          isFrozen: true,
          click: (row) => {
            let rows = [];
            if (row.InvQty > this.needAdoptQty) {
              row.AdoptQty = this.needAdoptQty;
            } else {
              row.AdoptQty = row.InvQty;
            }
            this.IsSingle = true;
            row.IsSingle = true;
            rows.push(row);
            this.onSaveRows(rows);
          },
        });
      columns = tempConfig.concat(columns);

      _.each(columns, (column, i) => {
        if (column.BindField == "AdoptQty" && this.isAdopt)
          column.isReadOnly = false;
        else column.isReadOnly = true;
      });
      if (!this.isAdopt) {
        _.remove(columns, (item) => {
          switch (item.BindField) {
            case "AdoptQty":
            case "CostAmountInTax":
              return true;
            default:
              break;
          }
        });
      }
      this.setHideColumns(columns);
      this.columns = columns;
      this.defaultConfig = config;
      if (this.parentPageSize > 0) {
        config.PageSize = this.parentPageSize;
      }
      this.pageSize = config.PageSize;
      this.role = config.Role;
      this.$refs.table.init();
      this.afterGridInit && this.afterGridInit();
      this.initData && this.onBindData();
    },
    async onBindData(pageIndex = 1) {
      this.loading = true;
      let fieldLast = {};
      if (this.searchModel.Keywords && this.searchModel.KeywordsField) {
        fieldLast[this.searchModel.KeywordsField] = this.searchModel.Keywords;
      }
      // 需采纳量
      this.searchFields &&
        this.searchFields.NeedAdoptQty &&
        (this.needAdoptQty = this.searchFields.NeedAdoptQty);
      fieldLast = _.extend({}, this.searchFields, fieldLast);

      let param = _.extend(
        {},
        {
          FieldWhereString: JSON.stringify(fieldLast),
          Model: this.searchModel.Model || "",
          Brand: this.searchModel.Brand || "",
          Packaging: this.searchModel.Packaging || "",
          AdoptstkGUID: this.searchModel.AdoptstkGUID,
          PageIndex: pageIndex,
          PageSize: this.pageSize,
        },
        {
          IsZero: this.searchModel.IsZero, // 零库存
          BDLineGUID:
            this.isAdopt === true ? this.searchModel.BDLineGUID : null,
          IsAnalyzer: this.IsAnalyzer,
          ASGUID: this.searchModel.ASGUID,
        }
      );

      return this.$post(CONFIG.searchURL, param, (data) => {
        this.pageIndex = pageIndex;
        // 给AdoptQty赋初始值 避免采纳时获取不到采纳数量 。
        _.each(data.ResultList, (item) => {
          if (item.AdoptQty === undefined) {
            item.AdoptQty = null;
          }
        });
        this.itemSource = data.ResultList;
        this.totalCount = data.TotalCount;
      }).finally(() => {
        this.loading = false;
      });
    },

    //  /** **********权限控制start*************/
    setHideColumns(columns) {
      // 成本价权限控制BuyPrice 采购价未税BuyPrice 和TaxBuyPrice 采购价(含税)  和 成本金额TotalCostAmount
      let viewBuyPrice = this.getSpecialResourceByCode("ViewBuyPrice");

      // 处理特殊权限  不看供应商资料(供应商、联系人、电话、手机、地址)
      let noSeeSupplier = this.getSpecialResourceByCode("NoSeeSupplier");

      // 成本价权限控制 SalesPrice 销售定价 FollowPrice 建议销售价
      let viewSalesPrice = this.getSpecialResourceByCode("ViewSalesPrice");

      // 批次库存 1=不可编辑
      let allowDirectlyUpdateStock = this.getCompanySetupValue(
        "AllowDirectlyUpdateStock"
      );
      if (allowDirectlyUpdateStock === "1") {
        this.isShowBtnSave = false;
      }
      _.remove(columns, (item) => {
        // 不看成本价
        if (
          !viewBuyPrice &&
          (item.BindField === "BuyPrice" ||
            item.BindField === "TaxBuyPrice" ||
            item.BindField === "TotalCostAmount")
        ) {
          this.permission.viewBuyPrice = false;
          return true;
        }
        // 不看销售价
        if (
          !viewSalesPrice &&
          (item.BindField === "SalesPrice" || item.BindField === "FollowPrice")
        ) {
          this.permission.viewSalesPrice = false;
          return true;
        }
        // 不看供应商
        if (noSeeSupplier && item.BindField === "SupplierName") {
          this.permission.noSeeSupplier = true;
          return true;
        }
        // 看备注1 没有这个权限隐藏列
        if (item.BindField === "Remark" && !this.hasRes("ViewRemark1")) {
          return true;
        }
        // 看备注2 没有这个权限隐藏列
        if (item.BindField === "Remark1" && !this.hasRes("ViewRemark2")) {
          return true;
        }
        // 看备注3 没有这个权限隐藏列
        if (item.BindField === "Remark2" && !this.hasRes("ViewRemark3")) {
          return true;
        }
        // 不看替代型号，有这个权限隐藏列
        if (
          item.BindField === "ReplaceModel" &&
          this.hasRes("NoSeeReplaceModel")
        ) {
          return true;
        }
        // 批次库存 1=不可编辑  所有列不可编辑
        if (!item.isReadOnly && allowDirectlyUpdateStock === "1") {
          item.isReadOnly = true;
        } else {
          // 没有编辑型号&数量的权限
          if (!this.hasRes("EditModelQty")) {
            switch (item.BindField) {
              case "Model":
              case "InvQty":
                item.isReadOnly = true;
                break;
            }
          }
          // 没有编辑库存属性的权限
          if (!this.hasRes("Save")) {
            if (item.BindField !== "Model" && item.BindField !== "InvQty") {
              item.isReadOnly = true;
            }
          }
        }
      });

      // 隐藏图片列
      let stockImageCol = _.find(
        columns,
        (item) => item.BindField === "StockImage"
      );
      if (stockImageCol) {
        stockImageCol.visible = false;
      }
    },
    // 权限列控制 end

    onSearch(params) {
      this.searchFields = _.extend({}, this.searchFields, params);
      return this.onBindData(1);
    },
    onHeadSearch(param) {
      this.searchModel.Model = param.Model || "";
      this.searchModel.Brand = param.Brand || "";
      this.searchModel.Packaging = param.Packaging || "";
      this.searchModel.IsZero = param.IsZero || false;
      this.searchModel.CustomerName = param.CustomerName || "";
      this.searchModel.Keywords = param.Keywords || "";
      this.searchModel.KeywordsField = param.KeywordsField || "";
      this.onBindData(1);
    },
    async onSaveRows(rows) {
      // console.log(123, rows)
      let isDiff = false;
      let msg = "";
      let diffMode = "";

      _.each(rows, (item) => {
        if (item.AdoptQty < 0) {
          msg = "采纳数量不能小于0";
          return;
        } else {
          msg = "";
        }
        item.BDLineGUID = this.searchModel.BDLineGUID;
        item.ASGUID = this.searchModel.ASGUID;
        if (this.hasValue(item.BuyPrice) && item.BuyPrice !== 0) {
          item.Price = item.BuyPrice;
        }
        if (item.Model !== this.searchModel.Model) {
          diffMode += item.Model + ",";
          isDiff = true;
        }
      });
      // diffMode = diffMode.Substring(0, diffMode.Length - 1)
      if (this.hasValue(msg)) {
        return this.$message({ message: msg, type: "error" });
      }
      if (isDiff) {
        let text =
          "采纳型号" +
          diffMode +
          "与需求型号" +
          this.searchModel.Model +
          "不一致，是否继续采纳?";
        await this.$confirm(text, "采纳提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          return this.onSaveAdopt(rows);
        });
      } else {
        return await this.onSaveAdopt(rows);
      }
    },
    async onSaveAdopt(rows) {
      if (!this.IsSingle) {
        await this.onSaveByAdoptQty(rows);
      } else {
        await this.onSaveBySingle(rows[0]);
      }
    },
    async onSaveByAdoptQty(rows) {
      if (rows.length > 0) {
        let array = [];
        _.each(rows, (item) => {
          let info = {
            BDLineGUID: item.BDLineGUID,
            Qty: item.AdoptQty,
            Price: item.BuyPrice,
            IAGUID: item.IAGUID,
            StkGUID: item.StkGUID,
            Brand: item.Brand,
            Packaging: item.Packaging,
            MPQ: item.MPQ,
            MakeYear: item.MakeYear,
            Quality: item.Quality,
            DeliveryDate: item.DeliveryDate,
            ASGUID: item.ASGUID,
          };
          array.push(info);
        });
        this.loading = true;
        return this.$post(CONFIG.saveURL, array, (datas, logic) => {
          if (logic.code === 200) {
            this.loading = false;
            this.$emit("save-success", datas);
            this.$refs.flexGrid.clearSelection();
            this.onBindData(this.pageIndex);
            this.$message({ message: "保存成功", type: "success" });
          } else {
            this.loading = false;
            this.$message({ message: logic.msg, type: "error" });
          }
        }).catch((e) => {
          this.loading = false;
        });
      }
    },
    // 按单条询价
    async onSaveBySingle(row) {
      let info = {
        BDLineGUID: row.BDLineGUID,
        Qty: row.AdoptQty,
        Price: row.BuyPrice,
        StkGUID: row.StkGUID,
        Brand: row.Brand,
        Packaging: row.Packaging,
        MPQ: row.MPQ,
        MakeYear: row.MakeYear,
        Quality: row.Quality,
        DeliveryDate: row.DeliveryDate,
        ASGUID: row.ASGUID,
        IsSingle: true,
      };
      this.loading = true;
      return this.$post(CONFIG.saveSingleURL, info, (datas, logic) => {
        if (logic.code === 200) {
          this.loading = false;
          this.$emit("save-success", datas);
          this.onBindData(this.pageIndex);
          this.$message({ message: "保存成功", type: "success" });
        } else {
          this.loading = false;
          this.$message({ message: logic.msg, type: "error" });
        }
      }).catch((e) => {
        this.loading = false;
      });
    },

    onDblClick(e) {
      if (e.rowData) {
        // 没有权限只是隐藏掉了价格列，价格不能清空，因为要跟着单据保存到数据库
        // if (!this.permission.viewBuyPrice) {
        //   e.rowData.BuyPrice = null;
        // }
        // if (!this.permission.viewSalesPrice) {
        //   e.rowData.SalesPrice = null;
        //   e.rowData.FollowPrice = null;
        // }

        // 没有看备注1权限，清空值
        if (!this.hasRes("ViewRemark1")) {
          e.rowData.Remark = null;
        }
        // 没有看备注2权限，清空值
        if (!this.hasRes("ViewRemark2")) {
          e.rowData.Remark1 = null;
        }
        // 没有看备注3权限，清空值
        if (!this.hasRes("ViewRemark3")) {
          e.rowData.Remark2 = null;
        }
      }
      this.$emit("dbl-click", e.rowData);
    },

    // 验证操作权限
    hasRes(code) {
      return _.includes(this.resourceList, code);
    },

    async OnAdoptPrice() {
      let rows = this.$refs.flexGrid.getChangedData();
      this.IsSingle = false;
      this.onSaveRows(rows);
    },
    // 表格设置的事件
    onHideShowColTrigger(e) {
      this.hideShowColTrigger("StkStock", e.col);
    },
    onDragDone(col) {
      this.setTableColWidth("StkStock", col);
    },
    onSaveConfig(cols, isDelay) {
      this.columns = cols;
      this.saveErpTableConfig("StkStock", cols, isDelay);
    },
    onResetTableConfigClick(e) {
      this.resetTableConfig("StkStock");
    },
  },
  // mounted () {
  //   this.$refs.flexGrid.toggleTableShow(false)
  // }
};
</script>

<style lang="scss">
</style>
